<template>
  <div class="classic-case">
    <form action="/">
      <van-search
          v-model="busgrp"
          show-action
          placeholder="请输入搜索关键词"
      >
        <div slot="action" @click="onClick">搜索</div>
      </van-search>
    </form>
    <van-row>
      <el-table
          :data="tableData"
          style="width: 100%">
        <el-table-column
            label="序号"
            type="index"
            width="50">
        </el-table-column>
        <el-table-column
            label="文件名称"
            width="180">
          <template scope="props">
            <div>
              <span>{{ props.row.useName }}</span>
            </div>
            <div class="secondRow" v-if="props.row.busgrp">
              <span>{{ props.row.busgrp }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="类型"
            width="80">
          <template scope="props">
            <div>
              <span>{{ props.row.fileType }}</span>
            </div>
            <div class="secondRow" v-if="props.row.creTime">
              <span>{{ props.row.creTime }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            width="60">
          <template slot-scope="scope">
            <el-button @click="downloadPDF(scope.row)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </van-row>
  </div>
</template>

<script>
import {Toast} from "vant";
import {getClassicCase, saveRecord} from '@/api/fileViewer'
export default {
  name: "ClassicCase",
  components: {},
  data() {
    return {
      tableData: [],
      busgrp: '',
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      getClassicCase({
        busgrp: this.busgrp
      }).then(res => {
        console.log(res)
        this.tableData = res
        Toast.success('加载成功！');
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败！');
      })

    },
    downloadPDF(row) {
      saveRecord({
        userNo: this.$store.getters.userId,
        busgrp: row.busgrp,
        fileName: row.fileName,
        fileType: row.fileType
      }).then(res => {
      })
      window.open("http://bulk-trawind-shipping.oss-cn-shanghai.aliyuncs.com/"+row.fileName)
    },
    onClick(){
      this.init();
    },
  }
}
</script>

<style scoped>
.classic-case {
  padding: 5px;
  background-color: #FFFFFF
}

.classic-case .secondRow{
  color: #66b1ff;
  /*padding-left: 10px;*/
}
</style>